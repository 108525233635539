import * as React from "react"
import { useRef } from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import map from "../images/map.png"
import emailjs from "emailjs-com"
import phoneIcon from "../images/phone-icon.svg"
import mailIcon from "../images/mail-icon.svg"
import { useTranslation } from 'gatsby-plugin-react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const form = useRef()

  function sendEmail(e) {
    e.preventDefault()

    emailjs
      .sendForm(
        "service_j9ih4ev",
        "template_2jxels8",
        e.target,
        "ZB8K3kcWTjcXgza3d"
      )
      .then(
        () => {
          alert("Form gönderildi. En kısa sürede sizinle iletişime geçeceğiz.")
          setTimeout(() => {
            window.location.reload()
          }, 2000);
        },
        error => {
          console.log(error.text)
        }
      )
  }
  return (
    <Layout>
      <Seo title="İletişim" />
      <div className="w-9/12 mx-auto mt-40 flex justify-between items-center md:flex-col md:w-11/12 md:mt-20">
        <div className="w-[38%] flex-col items-start bg-[#FFFEF9] pt-40 pb-20 drop-shadow-sm rounded-md mb-20 md:w-full md:py-5 md:mb-10">
          <h2 className="w-11/12 mx-auto font-second text-default-blue text-7xl mb-12 text-left md:text-center md:text-4xl leading-none font-normal md:mb-3">
            {t("bize")} <br className="md:hidden" />
            {t("ulasin")}
          </h2>
          <form
            ref={form}
            onSubmit={sendEmail}
            className="w-11/12 mx-auto flex flex-wrap"
          >
            <input
              type="text"
              name="user_name"
              required
              placeholder={t("name")}
              className="w-full bg-transparent border-b-2 border-default-opacity h-14 mb-10 p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base md:w-full md:mb-5"
            />
            <input
              type="email"
              name="user_email"
              required
              placeholder={t("mail")}
              className="w-full bg-transparent border-b-2 border-default-opacity h-14 mb-10 p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base md:mb-5"
            />
            <textarea
              name="message"
              required
              placeholder={t("message")}
              className="w-full bg-transparent border-b-2 border-default-opacity mb-10 md:w-full md:mb-5 p-3 font-first text-default placeholder:text-default placeholder:opacity-90 text-base resize-none"
              rows={6}
            />
            <input
              type="submit"
              value={t("submit")}
              className="h-14 rounded-md bg-default-blue text-white font-first font-light text-lg w-full cursor-pointer border-2 border-default hover:bg-transparent hover:text-default transition-all duration-300 md:w-full"
            />
          </form>
        </div>
        <div className="w-7/12 md:w-full">
          <a href="https://goo.gl/maps/fkME8fTzgGfQ5nsc7" target={"_blank"} rel="noreferrer">
            <img src={map} alt="map" className="w-full mb-5" />
          </a>
          <a href="https://goo.gl/maps/fkME8fTzgGfQ5nsc7" target={"_blank"} rel="noreferrer" className="text-default-blue font-medium text-xl font-first w-full md:text-sm md:text-center md:block">Osmanağa Mah. Söğütlüçeşme Cad. Altın Çarşı No:56/54 Kadıköy / İstanbul</a>
          <div className="w-full flex justify-between items-center mt-20 md:flex-col md:mt-10">
            <a href="tel:+905350684225" className="font-first text-default-blue font-medium text-xs flex items-center md:mb-8 md:flex-col">
              <img src={phoneIcon} alt="" className="mr-2 md:mr-0 md:mb-3 2xl:max-w-[30px] 2xl:mr-1" /> +90 535 068 4225
            </a>
            <a href="tel:+905319198138" className="font-first text-default-blue font-medium text-xs flex items-center md:mb-8 md:flex-col">
              <img src={phoneIcon} alt="" className="mr-2 md:mr-0 md:mb-3 2xl:max-w-[30px] 2xl:mr-1" /> +90 531 919 8138
            </a>
            <a href="mailto:info@marknconcept.com" className="font-first text-default-blue font-medium text-xs flex items-center md:mb-8 md:flex-col">
              <img src={mailIcon} alt="" className="mr-2 md:mr-0 md:mb-3 2xl:max-w-[30px] 2xl:mr-1" /> info@marknconcept.com
            </a>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contact

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
